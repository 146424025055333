import { defineStore } from 'pinia'
import {
  defaultRoutes,
  constantRoutes,
  notFoundRoutes
} from 'src/router/routes'

import {
  loginReq,
  loginRep,
  getUserInfoReq,
  getUserInfoRep
} from '../api/login'

import {
  getToken,
  getUserInfo,
  removeToken,
  setToken,
  setUserInfo
} from 'src/utils/auth'

export const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    userInfo: getUserInfo(),
    routes: [],
    roles: [],
    packageId: 1,
    lang: 'en'
  }),

  getters: {
    userName(state) {
      if (state.userInfo) {
        return state.userInfo.name
      }
    }
  },

  actions: {
    login(param) {
      return new Promise((resolve, reject) => {
        loginReq(param)
          .then((response) => {
            const rep = loginRep(response.data)
            this.token = rep.token
            this.userInfo = Object.assign(rep, {
              name: param.username,
              deviceId: param.deviceId,
              platform: param.platform
            })
            setToken(rep.token)
            setUserInfo(this.userInfo)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    logout() {
      this.token = null
      this.userInfo = null
      removeToken()
      // return new Promise((resolve, reject) => {
      //   logoutReq(this.userInfo).then((res) => {
      //     this.token = null
      //     this.userInfo = null
      //     removeToken()
      //     resolve()
      //   }).catch((err) => {
      //     reject(err)
      //   })
      // })
    },
    setRoles(role) {
      this.token = role.token
      getUserInfoReq(role.deviceId)
        .then((res) => {
          const rep = getUserInfoRep(res)
          console.log(rep)
          this.userInfo = Object.assign(rep.data, {
            name: rep.Account,
            deviceId: role.deviceId
          })
          setToken(role.token)
          setUserInfo(this.userInfo)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setPackageId(id) {
      this.packageId = id
    },
    setLang(lang) {
      this.lang = lang
    },
    setRoutes() {
      return new Promise((resolve) => {
        const routes = []
        const rolesMap = {}
        this.roles.forEach((role) => {
          rolesMap[role.nodePath] = role
        })

        for (const route of defaultRoutes) {
          const childMap = {}
          const children = []
          for (const child of rolesMap[route.path]?.children) {
            childMap[child.nodePath] = child.children.map(
              (node) => node.nodePath
            )
          }
          // 菜单子路由
          for (const child of route.children) {
            if (Object.keys(childMap).includes(child.path)) {
              // 路由按钮权限
              child.meta.roles = childMap[child.path]
              children.push(child)
            }
          }
          route.children = children
        }
        this.routes = constantRoutes.concat(routes).concat(notFoundRoutes)
        resolve()
      })
    }
  }
})

export const useGlobalStore = defineStore('global', {
  state: () => ({
    registeredUsername: null,
    registeredPassword: null,
    lang: 'zh',
    termPrivacy: 'termsOfService'
  }),

  getters: {
    userName(state) {
      if (state.userInfo) {
        return state.userInfo.name
      }
    }
  },

  actions: {
    setRegistered(param) {
      this.registeredUsername = param.username
      this.registeredPassword = param.password
    },
    removeRegistered() {
      this.registeredUsername = null
      this.registeredPassword = null
    },
    setLang(lang) {
      this.lang = lang
    },
    setTermPrivacy(param) {
      this.termPrivacy = param
    }
  }
})
